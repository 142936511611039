import React, { ReactNode } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../theme/types'

const styles = makeStyles((theme: Theme) => ({
  field: {
    marginRight: '20px',
    flexGrow: 1,
  },
  label: {
    color: theme.palette.darkGrey,
    fontSize: '12px',
    lineHeight: '16px',
  },
  value: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

interface IItemFieldProps {
  label?: string;
  grow?: number;
  style?: { [key: string]: string|number };
  fontWeight?: number;
  children: ReactNode | ReactNode[];
}

const ItemField = ({ label, grow, style, fontWeight, children }: IItemFieldProps) => {
  fontWeight = fontWeight === undefined ? 500 : fontWeight
  grow = grow === undefined ? 0 : grow
  const classes = styles();
  const fieldStyle = { ...style, flexGrow: grow }
  const valueStyle = { fontWeight }
  return (
    <div className={classes.field} style={fieldStyle}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value} style={valueStyle}>{children}</div>
    </div>
  )
}

export default ItemField;
