import React, {memo, useCallback, useMemo, useState} from 'react';

import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import {Theme} from '../../theme/types';

import {toSrcSet} from '../../utils/dom';
import {copyToClipboard} from '../../utils/copying';

import FixedLayout
  from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import ModalPage from '@vkontakte/vkui/dist/components/ModalPage/ModalPage';
import { ModalRootTouch as ModalRoot } from '@vkontakte/vkui/dist/components/ModalRoot/ModalRoot';
import ModalPageHeader from '../ModalPageHeader';
import PanelHeaderButton
  from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
// @ts-ignore FIXME: https://github.com/VKCOM/icons/issues/14
import CopyIcon from '@vkontakte/icons/dist/24/copy';
// @ts-ignore FIXME: https://github.com/VKCOM/icons/issues/14
import DismissIcon from '@vkontakte/icons/dist/24/dismiss';

import x1Url from '../../assets/emoji/sad/1x.png';
import x2Url from '../../assets/emoji/sad/2x.png';
import x4Url from '../../assets/emoji/sad/4x.png';
import { AppRoot, Group, Panel, Root, View } from '@vkontakte/vkui'

interface IProps {
  onRestartClick(): void;
  error: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  emoji: {
    height: 80,
    margin: '0 auto 16px',
  },
  button: {
    display: 'block',
    '& + &': {
      marginTop: 10,
    },
  },
  title: {
    textAlign: 'center',
    fontSize: 26,
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '30px',
    margin: 0,
  },
  error: {
    whiteSpace: 'pre-wrap',
  },
  header: {
    fontSize: 21,
    lineHeight: '26px',
  },
  dismissIcon: {
    color: '#818c99',
  },
  popup: {
    zIndex: -1,
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    opacity: 0,
    transition: 'opacity 0.3s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  popupVisible: {
    zIndex: 10,
    opacity: 1
  },
}));

/**
 * Вью которая отображается в случае, когда в приложении произошла ошибка.
 * @type {React.NamedExoticComponent<IProps>}
 */
const AppCrashedView = memo((props: IProps) => {
  const {onRestartClick, error} = props;
  const mc = useStyles(props);
  const [showError, setShowError] = useState(false);
  const [copying, setCopying] = useState(false);
  const srcSet = useMemo(() => toSrcSet([
    [x1Url, '1x'], [x2Url, '2x'], [x4Url, '4x'],
  ]), []);

  const onCopyClick = useCallback(async () => {
    setCopying(true);
    try {
      await copyToClipboard(error);
    } catch (e) {
    }
    setCopying(false);
  }, [error]);

  const modal = (
    <Div className={mc.popup + (showError ? ' ' + mc.popupVisible : '')}>
      <Div className={mc.error}>{error}</Div>
      <Div>
        <Button
          size="l"
          mode="secondary"
          stretched
          before={<CopyIcon/>}
          onClick={onCopyClick}
          disabled={copying}
          className={mc.button}
        >
          Скопировать
        </Button>
        <Button
          size="l"
          mode="primary"
          stretched
          onClick={onRestartClick}
          className={mc.button}
        >
          Перезапустить приложение
        </Button>
      </Div>
    </Div>
  )

  return (
    <div className={mc.root}>
      <img className={mc.emoji} src={x1Url} srcSet={srcSet} alt={''}/>
      <p className={mc.title}>Упс, что-то сломалось</p>
      <FixedLayout vertical={'bottom'}>
        <Div>
          <Button
            size="l"
            mode="primary"
            stretched
            onClick={onRestartClick}
            className={mc.button}
          >
            Перезапустить приложение
          </Button>
          <Button
            size="l"
            mode="secondary"
            stretched
            onClick={() => setShowError(true)}
            className={mc.button}
          >
            Подробнее об ошибке
          </Button>
        </Div>
      </FixedLayout>
      {modal}
    </div>
  );
});

export default AppCrashedView;
