import React, { Component } from "react"
import { AdminDay, adminDaysQuery, AdminDaysQuery } from 'bridge'
import DayItem from './DayItem'
import ApolloClient from 'apollo-boost'
import dayjs from 'dayjs'
import AdminPage from '../AdminPage'

interface IDaysProps {
  client: ApolloClient<any>;
}

interface IDaysState {
  loading: boolean;
  days: AdminDay[];
}

class Days extends Component<IDaysProps, IDaysState> {
  loading: true

  state: IDaysState = {
    loading: false,
    days: [],
  }

  constructor(props: IDaysProps) {
    super(props);
  }

  async componentDidMount() {
    const result = await this.props.client.query<AdminDaysQuery>({query: adminDaysQuery})
    const { data: { adminDays } } = result
    // adminDays.reverse()
    this.setState({
      loading: false,
      days: adminDays,
    })
  }

  add() {
    const newDay = {
      id: '',
      date: dayjs(new Date()).format('DD-MM-YYYY'),
      maxGamesCount: 5,
    } as AdminDay
    this.setState({ days: [newDay, ...this.state.days] })
  }

  render() {
    const {loading, days} = this.state
    return (
      <AdminPage title="Дни" loading={loading} add={() => this.add()}>
        {days.map((el, index) => (
          <DayItem key={days.length - index} day={el} index={days.length - index}/>
        ))}
      </AdminPage>
    )
  }
}

export default Days;
