import React, { ReactNode } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../theme/types'
import BackButton from './BackButton'

const styles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 10,
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
}));

interface IAdminModalProps {
  onClose: () => void;
  children: ReactNode | ReactNode[];
}

const AdminModal =({ onClose, children }: IAdminModalProps) => {
  const classes = styles()

  return (
    <div className={classes.root}>
      {children}
      <BackButton goBack={onClose}/>
    </div>
  )
}

export default AdminModal;
