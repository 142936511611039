import React, { useState } from "react"
import { AdminUser, FIELD } from 'bridge'
import UserForm from './UserForm'
import dayjs from 'dayjs'
import Item from '../Item'
import ItemField from '../ItemField'

interface IUserItemProps {
  user: AdminUser;
  index: number;
}

const UserItem =(props: IUserItemProps) => {
  const {user, index} = props;
  const [form, setForm] = useState({...user})

  const getFieldTitle = (field: string) => {
    const typeTitles: {[key: string]: string} = {
      [FIELD.ANDROID]: 'Android',
      [FIELD.IOS]: 'IOS',
      [FIELD.QA]: 'QA',
      [FIELD.BACKEND]: 'Backend',
      [FIELD.FRONTEND]: 'Frontend',
    }
    return typeTitles[field] || 'Не найден';
  }

  const isNew = !form.id;

  const date = dayjs(form.createdAt).format('YYYY-MM-DD HH:mm:ss');

  const profileImageStyle = {
    width: '50px',
    height: '50px',
  }

  return (
    <Item
      editForm={<UserForm user={form} update={setForm}/>}
      isNew={isNew}
      isHidden={form.disabled}
      index={index}
    >
      <ItemField label="Id Вконтакте" style={{ width: '100px' }}>
        {form.userId}
      </ItemField>
      <ItemField label="Имя" grow={1}>
        {form.name}
      </ItemField>
      <ItemField>
        {form.profileImageUrl ? (
          <img src={form.profileImageUrl} alt={form.name} style={profileImageStyle}/>
        ) : (
          <div style={profileImageStyle}/>
        )}
      </ItemField>
      <ItemField label="Направление">
        {getFieldTitle(form.field)}
      </ItemField>
      <ItemField label="Администратор">
        {form.isAdmin ? 'Да' : 'Нет'}
      </ItemField>
      <ItemField label="Дата регистрации">
        {date}
      </ItemField>
    </Item>
  )
}

export default UserItem;
