import React, { Component } from "react"
import { adminLobbiesQuery, AdminLobbiesQuery, AdminLobby, adminUsersQuery, AdminUsersQuery, AdminUser, LOBBY_TYPE } from 'bridge'
import LobbyItem from './LobbyItem'
import ApolloClient from 'apollo-boost'
import AdminPage from '../AdminPage'

interface ILobbiesProps {
  client: ApolloClient<any>;
}

interface ILobbiesState {
  loading: boolean;
  lobbies: AdminLobby[];
}

class Lobbies extends Component<ILobbiesProps, ILobbiesState> {
  loading: true

  state: ILobbiesState = {
    loading: false,
    lobbies: [],
  }

  users: AdminUser[]

  constructor(props: ILobbiesProps) {
    super(props);
  }

  async componentDidMount() {
    const [lobbiesResult, usersResult] = await Promise.all([
      this.props.client.query<AdminLobbiesQuery>({query: adminLobbiesQuery}),
      this.props.client.query<AdminUsersQuery>({query: adminUsersQuery}),
    ])
    const { data: { adminLobbies } } = lobbiesResult
    const { data: { adminUsers } } = usersResult
    this.users = adminUsers
    // adminLobbies.reverse()
    this.setState({
      loading: false,
      lobbies: adminLobbies,
    })
  }

  add() {
    const newLobby = {
      id: '',
      participantUserIds: [],
      type: LOBBY_TYPE.COMMON,
      isLaunched: false,
      isFinished: false,
    } as AdminLobby
    this.setState({ lobbies: [newLobby, ...this.state.lobbies] })
  }

  render() {
    const {loading, lobbies} = this.state
    return (
      <AdminPage title="Лобби" loading={loading} add={() => this.add()}>
        {lobbies.map((el, index) => (
          <LobbyItem key={lobbies.length - index} lobby={el}
                     users={this.users} index={lobbies.length - index}/>
        ))}
      </AdminPage>
    )
  }
}

export default Lobbies;
