import React, { Component } from "react"
import { AdminUser, adminUsersQuery, AdminUsersQuery, FIELD, LOBBY_TYPE } from 'bridge'
import UserItem from './UserItem'
import AppLoadingView from '../../views/AppLoadingView'
import ApolloClient from 'apollo-boost'
import AdminPage from '../AdminPage'

interface IUsersProps {
  client: ApolloClient<any>;
}

interface IUsersState {
  loading: boolean;
  users: AdminUser[];
}

class Users extends Component<IUsersProps, IUsersState> {
  loading: true

  state: IUsersState = {
    loading: false,
    users: [],
  }

  constructor(props: IUsersProps) {
    super(props);
  }

  async componentDidMount() {
    const result = await this.props.client.query<AdminUsersQuery>({query: adminUsersQuery})
    const { data: { adminUsers } } = result
    // adminUsers.reverse()
    this.setState({
      loading: false,
      users: adminUsers,
    })
  }

  add() {
    const newUser = {
      userId: 0,
      isAdmin: false,
      disabled: false,
    } as AdminUser
    this.setState({ users: [newUser, ...this.state.users] })
  }

  render() {
    const {loading, users} = this.state
    if (loading) {
      return <AppLoadingView/>
    }
    return (
      <AdminPage title="Пользователи" loading={loading} add={() => this.add()}>
        {users.map((el, index) => (
          <UserItem key={users.length - index}
                    user={el} index={users.length - index}/>
        ))}
      </AdminPage>
    )
  }
}

export default Users;
