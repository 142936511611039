import ApolloClient from 'apollo-boost';
import {HttpLink} from 'apollo-link-http';
import config from '../config';
import {WebSocketLink} from 'apollo-link-ws';
import {split} from 'apollo-link';
import {getMainDefinition} from 'apollo-utilities';

/**
 * Возвращает клиент Apollo
 * @returns {ApolloClient<any>}
 */
export function createApolloClient(): ApolloClient<any> {
  const client = new ApolloClient();

  const httpLink = new HttpLink({
    uri: config.apiBaseUrl + '/gql',
    headers: {
      'x-launch-params': window.location.search.slice(1),
    },
  });

  // console.log(config.wsUrl)
  const wsLink = new WebSocketLink({
    uri: config.wsUrl,
    options: {
      reconnect: true,
      connectionParams: {
        launchParams: window.location.search.slice(1),
      },
    },
  });

  const link = split(
    // split based on operation type
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  client.queryManager.link = link;
  client.link = link;

  return client;
}
