import React, { Component, FormEvent, useCallback } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../../theme/types'
import {
  adminSettingsQuery, AdminSettingsQuery, adminUpdateSettingsMutation, AdminUpdateSettingsMutation,
} from 'bridge'
import { Button, FormLayout, FormItem, Input } from '@vkontakte/vkui'
import AdminPage from '../AdminPage'
import ApolloClient from 'apollo-boost'

const styles = makeStyles((theme: Theme) => ({
  valueId: {
    width: '300px',
  },
  valueRadio: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-5px',
  },
}));

interface ISettingsProps {
  client: ApolloClient<any>;
}
interface ISettingsState {
  settings: {
    replyFormUrl: string;
  };
}

class Settings extends Component<ISettingsProps, ISettingsState> {
  state: ISettingsState = {
    settings: { replyFormUrl: '' },
  }

  constructor(props: ISettingsProps) {
    super(props);
  }

  async componentDidMount() {
    const result = await this.props.client.query<AdminSettingsQuery>({query: adminSettingsQuery})
    const settings = result.data.adminSettings
    console.log(result, settings)
    // @ts-ignore
    this.setState({
      settings,
    })
  }

  onChangeSetting(name: string, e: FormEvent) {
    const value = e.target.value
    // @ts-ignore
    this.setState({ settings: { [name]: value } })
  }

  async save(e) {
    e.preventDefault()

    // console.log(this.state.settings)
    await this.props.client.mutate<AdminUpdateSettingsMutation,
      AdminUpdateSettingsMutation.Arguments>({
      mutation: adminUpdateSettingsMutation,
      variables: {
        settings: this.state.settings,
      }
    })
  }

  render() {
    const { settings } = this.state
    return (
      <AdminPage title="Настройки">
        <FormLayout onSubmit={ (e) => this.save(e) }>
          <FormItem top="Url кнопки 'Заполнить форму обратной связи'">
            <Input
              value={ settings.replyFormUrl }
              onChange={ (e) => this.onChangeSetting('replyFormUrl', e) }
            />
          </FormItem>
          <FormItem>
            <Button mode="commerce" size="l">Сохранить</Button>
          </FormItem>
        </FormLayout>
      </AdminPage>
    )
  }
}

export default Settings;
