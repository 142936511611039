import React, { Component } from "react"
import {
  AdminQuestion,
  adminQuestionsQuery,
  AdminQuestionsQuery, adminUpdateQuestionMutation,
  AdminUpdateQuestionMutation,
  FIELD,
  LOBBY_TYPE
} from 'bridge'
import QuestionItem from './QuestionItem'
import ApolloClient from 'apollo-boost'
import AdminPage from '../AdminPage'
import { Button, File, FormItem } from '@vkontakte/vkui'
import {
  Icon24LockOutline,
  Icon24LockOpenOutline,
  Icon24Download,
  Icon24Upload,
  Icon24Document
} from '@vkontakte/icons'
import { downloadRaw } from '../../../utils/download-link'
import AdminModal from '../AdminModal'
import QuestionUploadModal from './QuestionUploadModal'
interface IQuestionsProps {
  client: ApolloClient<any>;
}

interface IQuestionsState {
  loading: boolean;
  questions: AdminQuestion[];
  checked: number[];
  uploadPopupOpened: boolean;
}

const fixForm = (form: AdminQuestion): AdminQuestion => {
  const defaultForm = {...form}
  delete defaultForm.createdAt
  if (defaultForm.id === '') {
    delete defaultForm.id
  }
  if ('__typename' in defaultForm) {
    // @ts-ignore
    delete defaultForm.__typename
  }
  return defaultForm
}

class Questions extends Component<IQuestionsProps, IQuestionsState> {
  loading: true

  state: IQuestionsState = {
    loading: false,
    questions: [],
    checked: [],
    uploadPopupOpened: false,
  }

  constructor(props: IQuestionsProps) {
    super(props);
  }

  async componentDidMount() {
    const result = await this.props.client.query<AdminQuestionsQuery>({query: adminQuestionsQuery})
    const { data: { adminQuestions } } = result
    // adminQuestions.reverse()
    this.setState({
      loading: false,
      questions: adminQuestions,
    })
  }

  add() {
    const newQuestion = {
      id: '',
      distance: 100,
      correctAnswer: 0,
      title: '',
      field: FIELD.ANDROID,
      options: [],
      type: LOBBY_TYPE.COMMON,
      hidden: false,
    } as AdminQuestion
    this.setState({ questions: [newQuestion, ...this.state.questions] })
  }

  isChecked(index: number): boolean {
    return this.state.checked.includes(index)
  }

  onCheckChange(index: number, value: boolean) {
    let newChecked = [...this.state.checked]
    if (value && !this.isChecked(index)) {
      newChecked.push(index)
    }
    if (!value && this.isChecked(index)) {
      newChecked = newChecked.filter(el => el !== index)
    }
    this.setState({
      checked: newChecked
    })
  }

  async update(question: AdminQuestion) {
    await this.props.client.mutate<
      AdminUpdateQuestionMutation,
      AdminUpdateQuestionMutation.Arguments
    >({
      mutation: adminUpdateQuestionMutation,
      variables: {
        question: fixForm(question)
      }
    })
  }

  showChecked() {
    const checked = this.state.checked
    const questions: AdminQuestion[] = JSON.parse(JSON.stringify(this.state.questions))
    questions.forEach(async (el, index) => {
      if (checked.includes(index)) {
        el.hidden = false
      }
      await this.update(el)
    })
    this.setState({
      checked: [],
      questions
    })
  }

  hideChecked() {
    const checked = this.state.checked
    const questions: AdminQuestion[] = JSON.parse(JSON.stringify(this.state.questions))
    questions.forEach(async (el, index) => {
      if (checked.includes(index)) {
        el.hidden = true
      }
      await this.update(el)
    })
    this.setState({
      checked: [],
      questions
    })
  }

  download() {
    const rows = this.state.questions
      .map((el, index) => ([
        el.id,
        index,
        el.type,
        el.field,
        el.distance,
        el.createdAt,
        el.title,
        el.correctAnswer,
        ...el.options,
      ]))
    rows.unshift([
      'Inner ID',
      'Index',
      'Type',
      'Field',
      'Distance',
      'Created At',
      'Title',
      'Correct Answer',
      'Answer 1',
      'Answer 2',
      'Answer 3',
      'Answer 4',
    ])
    const content = rows.map(el => '"' + el.join('";"') + '"').join("\n");
    const MIME_TYPE = "text/csv;charset=utf-8";
    const blob = new Blob([
      new Uint8Array([0xEF, 0xBB, 0xBF]),
      content
    ], {type: MIME_TYPE});
    const link = window.URL.createObjectURL(blob);
    downloadRaw(link)
  }

  openUploadPopup() {
    this.setState({ uploadPopupOpened: true })
  }

  importQuestions(questions: AdminQuestion[]) {
    this.setState({
      questions: [
        ...questions.reverse(),
        ...this.state.questions,
      ]
    })
  }

  render() {
    const {loading, questions, uploadPopupOpened} = this.state

    const actions = (
      <>
        <Button size="l" onClick={() => this.openUploadPopup()}>
          <Icon24Upload/>
        </Button>
        <Button size="l" onClick={() => this.download()}>
          <Icon24Download/>
        </Button>
        <Button disabled={!this.state.checked.length}
                size="l" onClick={() => this.showChecked()}>
          <Icon24LockOpenOutline/>
        </Button>
        <Button disabled={!this.state.checked.length}
                size="l" onClick={() => this.hideChecked()}>
          <Icon24LockOutline/>
        </Button>
      </>
    )

    let popup = (<></>)
    if (uploadPopupOpened) {
      popup = (
        <QuestionUploadModal
          close={() => this.setState({ uploadPopupOpened: false })}
          importQuestions={this.importQuestions.bind(this)}
        />
      )
    }

    return (
      <AdminPage title="Вопросы" loading={loading} add={() => this.add()} actions={actions}>
        {questions.map((el, index) => (
          <QuestionItem key={questions.length - index}
                        question={el} index={questions.length - index}
                        checked={this.isChecked(index)}
                        onCheckChange={(value) => this.onCheckChange(index, value)}/>
        ))}
        {popup}
      </AdminPage>
    )
  }
}

export default Questions;
