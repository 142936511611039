import {unionize, UnionOf} from 'unionize';
import {unionizeConfig} from '../utils';

export interface UserReducerState {
  isRegistered: boolean;
  isAdmin: boolean;
}

export const userActions = unionize({
  setUserRegistered: {},
}, unionizeConfig);

type UserAction = UnionOf<typeof userActions>;

const initialState: UserReducerState = {
  isRegistered: false,
  isAdmin: false,
};

/**
 * Редьюсер который отвечает за информацию о пользователе.
 * @param {StorageReducerState} state
 * @param {StorageAction} action
 * @returns {string[]}
 */
function userReducer(
  state: UserReducerState = initialState,
  action: UserAction,
) {
  return userActions.match(action, {
    setUserRegistered: () => ({...state, isRegistered: true}),
    default: () => state,
  });
}

export default userReducer;
