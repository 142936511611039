import React, { useState } from "react"
import { AdminDay } from 'bridge'
import DayForm from './DayForm'
import dayjs from 'dayjs'
import Item from '../Item'
import ItemField from '../ItemField'

interface IDayItemProps {
  day: AdminDay;
  index: number;
}

const DayItem =({day, index}: IDayItemProps) => {
  const [form, setForm] = useState({...day})

  const isNew = form.id === '';

  const date = dayjs(form.createdAt).format('YYYY-MM-DD HH:mm:ss');

  return (
    <Item
      editForm={<DayForm day={form} update={setForm}/>}
      isNew={isNew}
      index={index}
    >
      <ItemField label="Ограничение на количество гонок" grow={1}>
        {form.maxGamesCount}
      </ItemField>
      <ItemField label="Дата дня" grow={1}>
        {form.date}
      </ItemField>
      <ItemField label="Дата создания" grow={1}>
        {date}
      </ItemField>
    </Item>
  )
}

export default DayItem;
