import React, { ReactNode } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../theme/types'

const styles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
    marginTop: '-20px',
    padding: '20px 4px 8px',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.darkGrey}`,
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    '& .FormLayout__row .FormField, & .FormLayout__row .Button': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

interface IItemFormProps {
  children: ReactNode | ReactNode[];
}

const ItemForm = ({ children }: IItemFormProps) => {
  const classes = styles();
  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

export default ItemForm;
