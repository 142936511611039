import React, { FormEvent, MouseEvent, useState } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../../theme/types'
import {
  adminCreateQuestionMutation,
  AdminCreateQuestionMutation,
  AdminQuestion,
  adminUpdateQuestionMutation,
  AdminUpdateQuestionMutation,
  FIELD,
  LOBBY_TYPE
} from 'bridge'
import { Button, FormLayout, Radio, Spinner, Checkbox, Textarea, FormItem, Input } from '@vkontakte/vkui'
import { Icon24DeleteOutline, Icon24ListAdd } from '@vkontakte/icons';
import { useMutation } from '@apollo/react-hooks'
const styles = makeStyles((theme: Theme) => ({
  value: {},
  valueNumber: {
    width: '100px',
  },
  valueRadio: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '-5px',
    '& .vkuiRadio': {
      width: 'auto',
      marginRight: 0,
      '&:not(:first-child)': {
        marginLeft: 0,
      }
    }
  },
  answer: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    '& .vkuiTextarea': {
      flex: 1
    },
    '& .vkuiRadio': {
      width: 'auto',
      selfAlign: 'stretch',
      paddingTop: '10px',
      paddingBottom: '10px',
      marginLeft: '10px',
      marginRight: '10px',
      '& .vkuiRadio__icon': {
        marginRight: '1px'
      },
    },
    '& .vkuiButton': {
    }
  },
}));

interface IQuestionItemProps {
  question: AdminQuestion;
  getTypeTitle: (type: string) => string;
  getFieldTitle: (field: string) => string;
  update: (form: AdminQuestion) => void;
}

const fixForm = (form: AdminQuestion): AdminQuestion => {
  const defaultForm = {...form}
  delete defaultForm.createdAt
  if (defaultForm.id === '') {
    delete defaultForm.id
  }
  if ('__typename' in defaultForm) {
    // @ts-ignore
    delete defaultForm.__typename
  }
  return defaultForm
}

const QuestionItem =(props: IQuestionItemProps) => {
  const {question, getTypeTitle, getFieldTitle, update} = props;
  const [form, setForm] = useState(fixForm(question))
  const [hasChanges, setHasChanges] = useState(false)
  const [loading, setLoading] = useState(false)
  const classes = styles();

  // const { data, loading } = useQuery<AdminQuestionsQuery>(adminQuestionsQuery, {
  //   fetchPolicy: "network-only"
  // });

  const setFormProp = (name: string, value: any) => {
    setForm({...form, [name]: value})
    setHasChanges(true)
  }

  const onInput = ($event: FormEvent) => {
    const el = $event.target as HTMLInputElement
    const name = el.name
    const value = el.value
    if (el.type === 'number' || el.name === 'correctAnswer') {
      setFormProp(name, +value)
    } else if (el.name ==='hidden') {
      setFormProp(name, value === 'on')
    } else {
      setFormProp(name, value)
    }
  }

  const [updateMutation] = useMutation<AdminUpdateQuestionMutation,
    AdminUpdateQuestionMutation.Arguments>(adminUpdateQuestionMutation)

  const [createMutation] = useMutation<AdminCreateQuestionMutation,
    AdminCreateQuestionMutation.Arguments>(adminCreateQuestionMutation)

  const save = async () => {
    let resultForm
    if (form.id) {
      const {data} = await updateMutation({
        variables: {
          question: form
        }
      })
      resultForm = data.adminUpdateQuestion
    } else {
      const {data} = await createMutation({
        variables: {
          question: form
        }
      })
      resultForm = data.adminCreateQuestion
    }
    update(resultForm)
    setForm(fixForm(resultForm))
    setHasChanges(false)
  }

  const deleteOption = (index: number) => {
    const options = [...form.options]
    options.splice(index, 1)
    setFormProp('options', options)
  }
  const addOption = () => {
    setFormProp('options', [...form.options, ''])
  }
  const setOption = (index: number, value: string) => {
    const options = [...form.options]
    options[index] = value
    setFormProp('options', options)
  }
  const prevent = (cb: (...cbArgs: any) => void, ...args: any) => {
    return (event: MouseEvent|FormEvent) => {
      event.preventDefault()
      cb(...args, event)
    }
  }

  return (
    <FormLayout onSubmit={prevent(save)}>
      <FormItem top="Скрытый">
        <div className={classes.valueRadio}>
          <Checkbox name="hidden" defaultChecked={form.hidden} onChange={onInput}/>
        </div>
      </FormItem>
      <FormItem top="Вопрос">
        <div className={classes.value}>
          <Textarea name="title" value={form.title} onChange={onInput}/>
        </div>
      </FormItem>
      <FormItem top="Дистанция">
        <div className={classes.valueNumber}>
          <Input name="distance" value={form.distance} type="number" onChange={onInput}/>
        </div>
      </FormItem>
      <FormItem top="Тип">
        <div className={classes.valueRadio}>
          {[LOBBY_TYPE.COMMON, LOBBY_TYPE.SEMI_FINAL, LOBBY_TYPE.FINAL].map((type) => (
            <Radio key={type} name="type" value={type} defaultChecked={form.type === type}
                   onChange={onInput}>{getTypeTitle(type)}</Radio>
          ))}
        </div>
      </FormItem>
      <FormItem top="Направление">
        <div className={classes.valueRadio}>
          {[FIELD.ANDROID, FIELD.IOS, FIELD.QA, FIELD.BACKEND, FIELD.FRONTEND].map((field) => (
            <Radio key={field} name="field" value={field} defaultChecked={form.field === field}
                   onChange={onInput}>{getFieldTitle(field)}</Radio>
          ))}
        </div>
      </FormItem>
      <FormItem top="Ответы">
        <div className={classes.value}>
          {form.options.map((option, index) => (
            <div key={index} className={classes.answer}>
              <Textarea name="title" value={option}
                        onChange={(event) => setOption(index, event.target.value)}/>
              <Radio name="correctAnswer" value={index}
                     defaultChecked={form.correctAnswer === index}
                     onChange={onInput}/>
              <Button mode="destructive" onClick={prevent(deleteOption, index)}>
                <Icon24DeleteOutline/>
              </Button>
            </div>
          ))}
          <Button before={<Icon24ListAdd/>} onClick={prevent(addOption)} size="l">
            Добавить
          </Button>
        </div>
      </FormItem>
      <FormItem>
        <Button mode="commerce" disabled={!hasChanges} before={loading ? <Spinner/> : null}
                size="l">{form.id ? 'Сохранить' : 'Создать'}</Button>
      </FormItem>
    </FormLayout>
  )
}

export default QuestionItem;
