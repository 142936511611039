import React, { ReactNode, useState } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../theme/types'
import ItemForm from './ItemForm'
import { Icon28EditCircleFillBlue } from '@vkontakte/icons'

const styles = makeStyles((theme: Theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: '20px',
    },
  },
  item: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.darkGrey}`,
    borderRadius: '10px',
    padding: '10px 0',
    backgroundColor: 'white',
  },
  itemNew: {
    backgroundColor: '#f1f2fc'
  },
  itemHidden: {
    backgroundColor: '#fff6e0'
  },
  itemLaunched: {
    backgroundColor: '#f6e0ff'
  },
  itemFinished: {
    backgroundColor: '#e2ffe0'
  },
  index: {
    marginLeft: '20px',
    width: '30px',
    height: '30px',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 600,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.blue}`,
    color: theme.palette.blue,
    marginRight: '20px',
  },
  indexLong: {
    fontSize: '14px'
  },
  fieldEdit: {
    cursor: 'pointer',
    padding: '10px 20px 10px 10px',
  },
}));

interface IItemProps {
  children: ReactNode | ReactNode[];
  editForm?: ReactNode;
  index?: number;
  isNew?: boolean;
  isHidden?: boolean;
  isEdit?: boolean;
  isLaunched?: boolean;
  isFinished?: boolean;
  showEditButton?: boolean;
}

const Item = ({ children, editForm, index, isEdit,
                isLaunched, isFinished,
                isNew, isHidden, showEditButton }: IItemProps) => {
  showEditButton = showEditButton === undefined ? true : showEditButton
  const classes = styles();
  const [isLocalEdit, setIsLocalEdit] = useState(false)

  const indexClass = classes.index + (index >= 100 ? ` ${classes.indexLong}` : '')
  return (
    <div className={classes.root}>
      <div className={classes.item
          + ' ' + (isNew ? classes.itemNew : '')
          + ' ' + (isHidden ? classes.itemHidden : '')
          + ' ' + (isLaunched ? classes.itemLaunched : '')
          + ' ' + (isFinished ? classes.itemFinished : '')}
      >
        {index !== undefined && (
          <div className={indexClass}>{index}</div>
        )}
        {children}
        {showEditButton && (
          <div className={classes.fieldEdit} onClick={() => setIsLocalEdit(!isLocalEdit)}>
            <Icon28EditCircleFillBlue />
          </div>
        )}
      </div>
      {editForm && (isEdit || isLocalEdit) && (
        <ItemForm>
          {editForm}
        </ItemForm>
      )}
    </div>
  )
}

export default Item;
