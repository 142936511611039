import React, { FormEvent, MouseEvent, useState } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../../theme/types'
import {
  adminCreateUserMutation,
  AdminCreateUserMutation,
  AdminUser,
  adminUpdateUserMutation,
  AdminUpdateUserMutation,
} from 'bridge'
import { Button, FormLayout, Input, Spinner, Checkbox, FormItem } from '@vkontakte/vkui'
import { useMutation } from '@apollo/react-hooks'

const styles = makeStyles((theme: Theme) => ({
  valueId: {
    width: '300px',
  },
  valueRadio: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-5px',
  },
}));

interface IUserItemProps {
  user: AdminUser;
  update: (form: AdminUser) => void;
}

const fixForm = (form: AdminUser): AdminUser => {
  const defaultForm = {...form};
  ['name', 'profile', 'profileImageUrl', 'field', 'createdAt'].forEach(el => {
    if (el in defaultForm) {
      // @ts-ignore
      delete defaultForm[el]
    }
  })
  if ('__typename' in defaultForm) {
    // @ts-ignore
    delete defaultForm.__typename
  }
  return defaultForm
}

const UserItem =(props: IUserItemProps) => {
  const {user, update} = props;
  const [form, setForm] = useState(fixForm(user))
  const [hasChanges, setHasChanges] = useState(false)
  const [loading, setLoading] = useState(false)
  const classes = styles();

  // const { data, loading } = useQuery<AdminUsersQuery>(adminUsersQuery, {
  //   fetchPolicy: "network-only"
  // });

  const setFormProp = (name: string, value: any) => {
    setForm({...form, [name]: value})
    setHasChanges(true)
  }

  const onInput = ($event: FormEvent) => {
    const el = $event.target as HTMLInputElement
    const name = el.name
    const value = el.value
    if (el.type === 'number') {
      setFormProp(name, +value)
    } else if (['isAdmin', 'disabled'].includes(el.name)) {
      setFormProp(name, el.checked)
    } else {
      setFormProp(name, value)
    }
  }

  const [updateMutation] = useMutation<AdminUpdateUserMutation,
    AdminUpdateUserMutation.Arguments>(adminUpdateUserMutation)

  const [createMutation] = useMutation<AdminCreateUserMutation,
    AdminUpdateUserMutation.Arguments>(adminCreateUserMutation)

  const save = async () => {
    let resultForm
    if (user.id) {
      const {data} = await updateMutation({
        variables: {
          user: form
        }
      })
      resultForm = data.adminUpdateUser
    } else {
      const {data} = await createMutation({
        variables: {
          user: form
        }
      })
      resultForm = data.adminCreateUser
    }
    update(resultForm)
    setForm(fixForm(resultForm))
    setHasChanges(false)
  }

  const prevent = (cb: (...cbArgs: any) => void, ...args: any) => {
    return (event: MouseEvent|FormEvent) => {
      event.preventDefault()
      cb(...args, event)
    }
  }

  return (
    <FormLayout onSubmit={prevent(save)}>
      <FormItem top="ID Вконтакте">
        <div className={classes.valueId}>
          <Input name="userId" value={form.userId} type="number" onChange={onInput}/>
        </div>
      </FormItem>
      <FormItem top="Отключен">
        <div className={classes.valueRadio}>
          <Checkbox name="disabled" defaultChecked={form.disabled} onChange={onInput}/>
        </div>
      </FormItem>
      <FormItem top="Администратор">
        <div className={classes.valueRadio}>
          <Checkbox name="isAdmin" defaultChecked={form.isAdmin} onChange={onInput}/>
        </div>
      </FormItem>
      <FormItem>
        <Button mode="commerce" disabled={!hasChanges} before={loading ? <Spinner/> : null}
                size="l">{form.id ? 'Сохранить' : 'Создать'}</Button>
      </FormItem>
    </FormLayout>
  )
}

export default UserItem;
