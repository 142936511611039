import React, { useState } from "react"
import { AdminLobby, AdminUser, LOBBY_TYPE } from 'bridge'
import LobbyForm from './LobbyForm'
import dayjs from 'dayjs'
import Item from '../Item'
import ItemField from '../ItemField'
import AdminModal from '../AdminModal'
import GameLoader from '../../Game/GameLoader'

interface ILobbyItemProps {
  lobby: AdminLobby;
  index: number;
  users: AdminUser[];
}

const LobbyItem =(props: ILobbyItemProps) => {
  const {lobby, index, users} = props;
  const [form, setForm] = useState({...lobby})
  const [watching, setWatching] = useState(false)
  const getTypeTitle = (type: string) => {
    const typeTitles: {[key: string]: string} = {
      [LOBBY_TYPE.COMMON]: 'Общий',
      [LOBBY_TYPE.SEMI_FINAL]: 'Полуфинал',
      [LOBBY_TYPE.FINAL]: 'Финал',
    }
    return typeTitles[type] || 'Не найден'
  }

  const isNew = form.id === '';

  const date = dayjs(form.createdAt).format('YYYY-MM-DD HH:mm:ss');

  let modal = <></>
  if (watching && form.id) {
    modal = (
      <AdminModal onClose={() => setWatching(false)}>
        <GameLoader lobbyId={form.id} usersNumber={form.participantUserIds.length} />
      </AdminModal>
    )
  }

  let status = 'Не начат'
  if (form.isLaunched) {
    status = 'Запущен'
  }
  if (form.isFinished) {
    status = 'Завершен'
  }

  return (
    <>
      <Item
        editForm={<LobbyForm lobby={form} users={users}
                             watching={watching}
                             setWatching={setWatching}
                             getTypeTitle={getTypeTitle} update={setForm}/>}
        isNew={isNew}
        isLaunched={form.isLaunched}
        isFinished={form.isFinished}
        index={index}
      >
        <ItemField label="Тип" grow={1}>
          {form.type}
        </ItemField>
        <ItemField label="Пользователи" grow={1}>
          {form.participantUserIds.join(', ')}
        </ItemField>
        <ItemField label="Статус" grow={1}>
          {status}
        </ItemField>
        <ItemField label="Дата создания" grow={1}>
          {date}
        </ItemField>
      </Item>
      {modal}
    </>
  )
}

export default LobbyItem;
