import React, { ReactNode } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../theme/types'
import { Button } from '@vkontakte/vkui'
import { Icon24ListAdd } from '@vkontakte/icons'
import AppLoadingView from '../views/AppLoadingView'

const styles = makeStyles((theme: Theme) => ({
  wrapper: {
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  head: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    margin: '0 0 30px',
  },
  title: {
    fontSize: '50px',
    fontWeight: 600,
    color: theme.palette.blue,
    margin: '0',
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-start',
    '& .vkuiButton': {
      marginLeft: '20px'
    }
  }
}));

interface IAdminPageProps {
  title: string;
  add?: () => void;
  loading?: boolean;
  children: ReactNode | ReactNode[];
  actions?: ReactNode | ReactNode[];
}

const AdminPage = ({ title, add, loading, children, actions }: IAdminPageProps) => {
  const classes = styles();
  if (loading) {
    return <AppLoadingView/>
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.head}>
        <h2 className={classes.title}>{title}</h2>
        <div className={classes.actions}>
          {actions}
          {add && (
            <Button before={<Icon24ListAdd/>} size="l" onClick={() => add()}>
              Добавить
            </Button>
          )}
        </div>
      </div>
      <div className={classes.row}>
        {children}
      </div>
    </div>
  )
}

export default AdminPage;
