import React, { SyntheticEvent } from "react"
import { adminCreateQuestionMutation, AdminCreateQuestionMutation, AdminQuestion, FIELD, LOBBY_TYPE, } from 'bridge'
import { File, FormItem } from '@vkontakte/vkui'
import AdminModal from '../AdminModal'
import { Icon24Document } from '@vkontakte/icons'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../../theme/types'

const styles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  inner: {
    width: '100%',
    maxWidth: '400px',
  }
}));

interface IQuestionUploadModalProps {
  close: () => void;
  importQuestions: (questions: AdminQuestion[]) => any;
}

const QuestionUploadModal =(props: IQuestionUploadModalProps) => {
  const classes = styles();
  const {close, importQuestions} = props;

  const [createMutation] = useMutation<AdminCreateQuestionMutation,
    AdminCreateQuestionMutation.Arguments>(adminCreateQuestionMutation)

  const onChange = async (e: SyntheticEvent) => {
    const file = (e.target?.files || [])[0]
    const res = []
    if (file) {
      const contents = await new Promise<string>((resolve) => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result as string)
        };
        fr.readAsText(file, 'windows-1251');
      })
      const rows = contents.split("\n")
      rows.shift()
      for (const row of rows) {
        const data = row.split(';');
        const optionsFrom = 8;
        const options = [data[optionsFrom], data[optionsFrom + 1],
          data[optionsFrom + 2], data[optionsFrom + 3]]
          .filter(el => el)
          .map(el => '' + el);
        const type = Object.values(LOBBY_TYPE).includes('' + data[2] as LOBBY_TYPE)
          ? '' + data[2]
          : LOBBY_TYPE.COMMON;
        const field = Object.values(FIELD).includes('' + data[3] as FIELD)
          ? '' + data[3]
          : FIELD.BACKEND;
        const distance = +data[4];
        const title = '' + data[6];
        const correctAnswer = +data[7] || 0;
        if (!title || !distance || !options.length) {
          continue;
        }
        const question = {
          type,
          field,
          distance,
          title,
          correctAnswer,
          options,
          hidden: false,
        } as AdminQuestion
        res.push(question)
        await createMutation({
          variables: {
            question
          }
        })
      }
    }
    await importQuestions(res)
    close()
  }

  return (
    <AdminModal
      onClose={close}
    >
      <div className={classes.wrapper}>
        <div>
          <div>Загрузите CSV.<br/>Формат см. в файлах экспорта.<br/>
            Первый столбцы ID, Index, createdAt игнорируется и могут быть<br/>
            заполнены пустыми значениями</div>
          <FormItem top="" className={classes.inner}>
            <File before={<Icon24Document />} controlSize="l" mode="secondary" onChange={onChange}/>
          </FormItem>
        </div>
      </div>
    </AdminModal>
  )
}

export default QuestionUploadModal;
