import React, { PureComponent } from "react";

import AppLoadingView from "../views/AppLoadingView";
import App from "../App";
import { Provider as StoreProvider } from "react-redux";
import ThemeProvider from "../ThemeProvider";
import AppCrashView from "../views/AppCrashView";

import createReduxStore from "../../redux";
import vkBridge, {
  Insets,
  UpdateConfigData,
  VKBridgeSubscribeHandler
} from "@vkontakte/vk-bridge";
import { configActions } from "../../redux/reducers/config";
import { getStorageValues } from "../../utils/bridge";

import { Store } from "redux";
import { ReduxState } from "../../redux/types";
import { StorageField } from "../../types/bridge";
import { ApolloProvider } from "@apollo/react-hooks";

import {
  adminCurrentUserQuery,
  AdminCurrentUserQuery,
} from 'bridge'
import { createApolloClient } from '../../utils/apollo'
import { AdaptivityProvider, AppRoot, ConfigProvider } from '@vkontakte/vkui'

interface IState {
  loading: boolean;
  error: string | null;
  store: Store<ReduxState> | null;
}

/**
 * Является "мозгом" приложение. Если быть более конкретным - его
 * корнем. Здесь подгружаются все необходимые для работы приложения данные,
 * а также создаются основные контексты.
 */
class Root extends PureComponent<{}, IState> {
  public state: Readonly<IState> = {
    loading: false,
    error: null,
    store: null,
  };

  private client = createApolloClient();

  /**
   * Переменная которая отвечает за то, что было ли отправлено событие
   * обновления конфига приложения. Необходимо в случае, когда это событие
   * успели отловить но в тот момент Redux-хранилища еще не существовало.
   * @type {null}
   */
  private initialAppConfig: UpdateConfigData | null = null;

  /**
   * Аналогично initialAppConfigSent.
   * @type {null}
   */
  private initialAppInsets: Insets | null = null;

  /**
   * false if site opened not in vk iframe
   */
  private isVk = true;

  /**
   * Иницилизирует приложение.
   */
  private async init() {
    this.setState({ loading: true, error: null });

    let error: string | null = null;
    let store: Store<ReduxState> | null = null;

    try {
      // Здесь необходимо выполнить все асинхронные операции и получить
      // данные для запуска приложения, после чего создать хранилище Redux.
      const getStoragePromise = this.isVk ? getStorageValues(...Object.values(StorageField)) : {}
      const [storage, adminUserResult] = await Promise.all([
        getStoragePromise,
        this.client.query<AdminCurrentUserQuery>({query: adminCurrentUserQuery}),
      ]);
      // console.log([storage, adminUserResult])
      const isAdmin = adminUserResult.data.adminCurrentUser.isAdmin;

      store = createReduxStore({
        storage,
        user: {
          isRegistered: true,
          isAdmin
        }
      });

    } catch (e) {
      // В случае ошибки, мы её отловим и покажем экран с ошибкой.
      const err = e as Error;
      error = err.message;
    }

    this.setState({ store, error, loading: false });
  }

  /**
   * Проверяет, является событие VKWebAppUpdateConfig или VKWebAppUpdateInsets
   * чтобы узнать каков конфиг приложения в данный момент, а также - какие
   * внутренние рамки экрана существуют.
   * @param {VKBridgeEvent<ReceiveMethodName>} event
   */
  private onVKBridgeEvent: VKBridgeSubscribeHandler = event => {
    const {store} = this.state;

    if (event.detail) {
      if (event.detail.type === 'VKWebAppUpdateConfig') {
        if (store) {
          store.dispatch(configActions.updateConfig(event.detail.data));
        } else {
          this.initialAppConfig = event.detail.data;
        }
      } else if (event.detail.type === 'VKWebAppUpdateInsets') {
        if (store) {
          store.dispatch(
            configActions.updateInsets(event.detail.data.insets),
          );
        } else {
          this.initialAppInsets = event.detail.data.insets;
        }
      }
    }
  };

  public componentDidMount() {
    // Когда компонент загрузился, мы ожидаем обновления внутренних рамок
    // и конфига приложения.

    vkBridge.subscribe(this.onVKBridgeEvent);

    // Уведомляем нативное приложение о том, что инициализация окончена.
    // Это заставит нативное приложение спрятать лоадер и показать наше
    // приложение.
    // Причина по которой мы проводим инициализацию здесь - нативное приложение
    // автоматически отправлять информацию о конфиге и внутренних рамках,
    // которая нам нужна.
    this.isVk = !window.location.search.match(/[?&]vk=0(&|$)/)
    vkBridge.send("VKWebAppInit");

    // Инициализируем приложение.
    this.init();
  }

  public componentDidUpdate(prevProps: {}, prevState: Readonly<IState>) {
    const { store } = this.state;

    // Как только хранилище появилось, проверяем, были ли получены до этого
    // информация о конфиге и инсетах. Если да, то записываем в хранилище.
    // TODO: Перенести диспатчи в место где создается хранилище.
    if (prevState.store === null && store !== null) {
      if (this.initialAppConfig) {
        store.dispatch(configActions.updateConfig(this.initialAppConfig));
      }
      if (this.initialAppInsets) {
        store.dispatch(configActions.updateInsets(this.initialAppInsets));
      }
    }
  }

  public componentDidCatch(error: Error) {
    // Отлавливаем ошибку, если выше этого не произошло.
    this.setState({ error: error.message });
  }

  public componentWillUnmount() {
    // При разгрузке удаляем слушателя событий.
    vkBridge.unsubscribe(this.onVKBridgeEvent);
  }

  public render() {
    const { loading, error, store } = this.state;

    // Отображаем ошибку если она была.
    if (error) {
      return (
        <ThemeProvider>
          <ConfigProvider>
            <AdaptivityProvider>
              <AppRoot>
                <AppCrashView onRestartClick={this.init} error={error} />
              </AppRoot>
            </AdaptivityProvider>
          </ConfigProvider>
        </ThemeProvider>
      );
    }

    // Отображаем лоадер если приложение еще загружается.
    if (loading || !store) {
      return <AppLoadingView />;
    }

    // Отображаем приложение если у нас есть всё, что необходимо.
    return (
      <StoreProvider store={store}>
        <ApolloProvider client={this.client}>
          <ThemeProvider>
            <ConfigProvider>
              <AdaptivityProvider>
                <AppRoot>
                  <App isVk={this.isVk}/>
                </AppRoot>
              </AdaptivityProvider>
            </ConfigProvider>
          </ThemeProvider>
        </ApolloProvider>
      </StoreProvider>
    );
  }
}

export default Root;
