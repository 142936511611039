import React, { FormEvent, MouseEvent, useState } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../../theme/types'
import {
  AdminLobby,
  adminCreateLobbyMutation,
  AdminCreateLobbyMutation,
  adminUpdateLobbyMutation,
  AdminUpdateLobbyMutation, LOBBY_TYPE,
  AdminUser,
} from 'bridge'
import {
  Button,
  FormLayout,
  Radio,
  Spinner,
  CustomSelectOption,
  FormItem, CustomSelect
} from '@vkontakte/vkui'
import { useMutation } from '@apollo/react-hooks'
import { Icon24DeleteOutline, Icon24ListAdd } from '@vkontakte/icons'

const styles = makeStyles((theme: Theme) => ({
  value: {},
  valueNumber: {
    width: '100px',
  },
  valueRadio: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '-5px',
    '& .vkuiRadio': {
      width: 'auto',
      marginRight: 0,
      '&:not(:first-child)': {
        marginLeft: 0,
      }
    }
  },
  answer: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    '& .vkuiTextarea': {
      flex: 1
    },
    '& .vkuiRadio': {
      width: 'auto',
      selfAlign: 'stretch',
      paddingTop: '10px',
      paddingBottom: '10px',
      marginLeft: '10px',
      marginRight: '10px',
      '& .vkuiRadio__icon': {
        marginRight: '1px'
      },
    },
    '& .vkuiButton': {
    }
  },
  watchButton: {
    display: 'inline-block',
    marginLeft: '10px'
  },
}));

interface ILobbyItemProps {
  lobby: AdminLobby;
  getTypeTitle: (type: string) => string;
  update: (form: AdminLobby) => void;
  users: AdminUser[];
  watching: boolean;
  setWatching: (watch: boolean) => void;
}

const fixForm = (form: AdminLobby): AdminLobby => {
  const defaultForm = {...form}
  delete defaultForm.createdAt
  delete defaultForm.isLaunched
  delete defaultForm.isFinished
  if ('__typename' in defaultForm) {
    // @ts-ignore
    delete defaultForm.__typename
  }
  return defaultForm
}

const LobbyItem =(props: ILobbyItemProps) => {
  const {lobby, getTypeTitle, update, users, watching, setWatching} = props;
  const [form, setForm] = useState(fixForm(lobby))
  const [hasChanges, setHasChanges] = useState(false)
  const [loading, setLoading] = useState(false)
  const classes = styles();

  // const { data, loading } = useQuery<AdminLobbysQuery>(adminLobbysQuery, {
  //   fetchPolicy: "network-only"
  // });

  const setFormProp = (name: string, value: any) => {
    setForm({...form, [name]: value})
    setHasChanges(true)
  }

  const onInput = ($event: FormEvent) => {
    const el = $event.target as HTMLInputElement
    const name = el.name
    const value = el.value
    if (el.type === 'number') {
      setFormProp(name, +value)
    } else if (el.name ==='hidden') {
      setFormProp(name, value === 'on')
    } else {
      setFormProp(name, value)
    }
  }

  const [updateMutation] = useMutation<AdminUpdateLobbyMutation,
    AdminUpdateLobbyMutation.Arguments>(adminUpdateLobbyMutation)

  const [createMutation] = useMutation<AdminCreateLobbyMutation,
    AdminUpdateLobbyMutation.Arguments>(adminCreateLobbyMutation)

  const save = async () => {
    let resultForm
    if (form.id) {
      const {data} = await updateMutation({
        variables: {
          lobby: form
        }
      })
      resultForm = data.adminUpdateLobby
    } else {
      const {data} = await createMutation({
        variables: {
          lobby: form
        }
      })
      resultForm = data.adminCreateLobby
    }
    update(resultForm)
    setForm(fixForm(resultForm))
    setHasChanges(false)
  }

  const deleteUser = (index: number) => {
    const options = [...form.participantUserIds]
    options.splice(index, 1)
    setFormProp('participantUserIds', options)
  }
  const addUser = () => {
    setFormProp('participantUserIds', [...form.participantUserIds, ''])
  }
  const setUser = (index: number, value: number) => {
    const userIds = [...form.participantUserIds]
    userIds[index] = value
    setFormProp('participantUserIds', userIds)
  }

  const prevent = (cb: (...cbArgs: any) => void, ...args: any) => {
    return (event: MouseEvent|FormEvent) => {
      event.preventDefault()
      cb(...args, event)
    }
  }
  // options={users.map(el => ({ label: el.name, value: el.id }))}
  // renderOption={({ option, ...restProps }) => (
  //   <div>#{JSON.stringify(option)}<br/><br/>{JSON.stringify(restProps)}</div>
  // )}

  const userOptions = users.map(el => ({
    ...el,
    label: `#${el.userId} - ${el.name}`,
    value: el.userId
  }))

  const canWatch = true

  const watch = () => {
    setWatching(true)
  }

  return (
    <FormLayout onSubmit={prevent(save)}>
      <FormItem top="Тип">
        <div className={classes.valueRadio}>
          {[LOBBY_TYPE.COMMON, LOBBY_TYPE.SEMI_FINAL, LOBBY_TYPE.FINAL].map((type) => (
            <Radio key={type} name="type" value={type}
                   defaultChecked={form.type === type}
                   disabled={form.isLaunched || form.isFinished}
                   onChange={onInput}>{getTypeTitle(type)}</Radio>
          ))}
        </div>
      </FormItem>
      <FormItem top="Пользователи">
        <div className={classes.value}>
          {form.participantUserIds.map((userId, index) => (
            <div key={index} className={classes.answer}>
              <CustomSelect
                value={userId}
                options={userOptions}
                disabled={form.isLaunched || form.isFinished}
                onChange={(event) => setUser(index, +event.target.value)}
                renderOption={({ option, ...restProps }) => (
                  <CustomSelectOption
                    {...restProps}
                  >
                    #{option.userId} - {option.name}
                  </CustomSelectOption>
                )}
              />
              <Button mode="destructive" onClick={prevent(deleteUser, index)}
                      style={{ marginLeft: '20px' }}
                      disabled={form.isLaunched || form.isFinished}>
                <Icon24DeleteOutline/>
              </Button>
            </div>
          ))}
          <Button before={<Icon24ListAdd/>} onClick={prevent(addUser)} size="l"
                  disabled={form.isLaunched || form.isFinished}>
            Добавить
          </Button>
        </div>
      </FormItem>
      <FormItem>
        <Button mode="commerce"
                disabled={!hasChanges || form.isLaunched || form.isFinished}
                before={loading ? <Spinner/> : null}
                size="l">{form.id ? 'Сохранить' : 'Создать'}</Button>
        <div className={classes.watchButton}>
          <Button disabled={!canWatch} size="l" onClick={prevent(watch)}>Смотреть</Button>
        </div>
      </FormItem>
    </FormLayout>
  )
}

export default LobbyItem;
