import React, { memo, ReactElement, useState } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../theme/types'
import Menu from '../Menu'
import Questions from './Question/Questions'
import { ApolloConsumer } from '@apollo/react-hooks'
import Days from './Day/Days'
import Lobbies from './Lobby/Lobbies'
import Users from './User/Users'
import Export from './Export/Export'
import Settings from './Settings/Settings'

const styles = makeStyles((theme: Theme) => ({
  row: {
    width: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    paddingLeft: '220px',
    boxSizing: 'border-box',
    '& .Button': {
      cursor: 'pointer'
    }
  },
  menu: {
    width: '220px',
    borderRight: `1px solid ${theme.palette.blue}`,
    height: '100vh',
    boxSizing: 'border-box',
    position: 'fixed',
    left: 0,
    top: 0,
  },
  body: {
    minHeight: '100%',
    width: '100%',
    padding: '20px 40px 40px',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '50px',
    fontWeight: 600,
    color: theme.palette.blue,
    margin: '0 0 30px',
  },
}));

interface IAdminProps {
}

const Admin = memo((props: IAdminProps) => {
  const [page, setPage] = useState('questions')
  const {} = props;
  const classes = styles();

  const getTitle = (page: string): string => {
    const titles: { [key: string]: string } = {
      questions: 'Вопросы',
      days: 'Дни',
      lobbies: 'Лобби',
    };
    return titles[page] || 'Страница не найдена';
  }

  const getComponent = (page: string): ReactElement => {
    const components: { [key: string]: ReactElement } = {
      questions: (
        <ApolloConsumer>{client => <Questions client={client}/>}</ApolloConsumer>),
      days: (
        <ApolloConsumer>{client => <Days client={client}/>}</ApolloConsumer>),
      lobbies: (
        <ApolloConsumer>{client => <Lobbies client={client}/>}</ApolloConsumer>),
      users: (
        <ApolloConsumer>{client => <Users client={client}/>}</ApolloConsumer>),
      export: (
        <Export/>
      ),
      settings: (
        <ApolloConsumer>{client => <Settings client={client}/>}</ApolloConsumer>
      )
    };
    return components[page] || (<div/>);
  }

  return (
    <div className={classes.row}>
      <Menu page={page} setPage={setPage} className={classes.menu}/>
      <div className={classes.body}>
        {getComponent(page)}
      </div>
    </div>
  )
})

export default Admin;
