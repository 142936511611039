import React, { useEffect, useState } from "react";

//@ts-ignore

import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/all";
import {
  useLazyQuery,
  useMutation,
  useSubscription
} from "@apollo/react-hooks";

import { IGameProps } from "./types";

import Timer from "../Timer";
import LeadersBoard from "../LeadersBoard/LeadersBoard";
import AppLoadingView from "../views/AppLoadingView";
import Race from "../Race";
import {
  StartLobbyMutation,
  startLobbyMutation,
  LobbyQuery,
  lobbyQuery,
  LobbyLeaderboardQuery,
  lobbyLeaderboardQuery
} from "bridge";
import { Lobby, lobbySubscription, LobbySubscription } from "bridge";

const Game = (props: IGameProps) => {
  gsap.registerPlugin(MotionPathPlugin);

  const {
    data: lobbySubscriptionMeta,
    loading: changedQuestionLoading
  } = useSubscription<LobbySubscription, LobbySubscription.Arguments>(
    lobbySubscription,
    {
      variables: {
        lobbyId: props.raceId
      }
      //shouldResubscribe: true
    }
  );

  const [startRaceMutation, startRaceMutationMeta] = useMutation<
    StartLobbyMutation,
    StartLobbyMutation.Arguments
  >(startLobbyMutation, {
    variables: {
      lobbyId: props.raceId
    }
  });

  const [getCurrentRaceData, getCurrentRaceDataMeta] = useLazyQuery<
    LobbyQuery,
    LobbyQuery.Arguments
  >(lobbyQuery, {
    variables: {
      lobbyId: props.raceId
    }
  });

  const [getLeadersBoard, getLeadersBoardMeta] = useLazyQuery<
    LobbyLeaderboardQuery,
    LobbyLeaderboardQuery.Arguments
  >(lobbyLeaderboardQuery, {
    variables: {
      lobbyId: props.raceId
    },
    fetchPolicy: "network-only"
  });

  const [finishedAnimations, setFinishedAnimations] = useState<number>(0);

  const [loading, setLoading] = useState(false);

  //контролирует, закончил ли таймер анимацию
  const [timerSatus, setTimerStatus] = useState(false);

  if (props.isHelding && !timerSatus) {
    setTimerStatus(true);
  }

  useEffect(() => {
    //console.log(getCurrentRaceData);
    if (props.isHelding) {
      getCurrentRaceData();
    }
  }, []);

  useEffect(() => {}, [lobbySubscriptionMeta?.lobby?.users[0]?.timeLeft]);

  useEffect(() => {
    if (
      finishedAnimations === lobbySubscriptionMeta?.lobby?.users.length &&
      lobbySubscriptionMeta?.lobby?.resultsCalculated &&
      !getCurrentRaceDataMeta.loading
    ) {
      console.log("Все готово для показа таблицы лидеров");
      console.log(lobbySubscriptionMeta?.lobby?.resultsCalculated);

      getLeadersBoard();
    }
  }, [finishedAnimations, lobbySubscriptionMeta?.lobby?.resultsCalculated]);

  if (
    getLeadersBoardMeta.loading &&
    getLeadersBoardMeta.called &&
    !lobbySubscriptionMeta?.lobby?.resultsCalculated
    //!getCurrentRaceDataMeta?.data?.lobby
  ) {
    console.log("гружу результаты заезда");

    return <AppLoadingView />;
  }

  if (
    !getLeadersBoardMeta.loading &&
    getLeadersBoardMeta.called &&
    lobbySubscriptionMeta?.lobby?.resultsCalculated &&
    getLeadersBoardMeta?.data?.lobbyLeaderboard.length > 0
  ) {
    console.log(getLeadersBoardMeta.data.lobbyLeaderboard);

    return <LeadersBoard data={getLeadersBoardMeta.data.lobbyLeaderboard} />;
  }

  const timerCallback = () => {
    console.log("отправил инфу о запуске таймера");

    startRaceMutation();
  };

  const timerAnimationCallback = () => {
    setLoading(true);
    setTimeout(() => {
      console.log("Убрал таймер");

      setTimerStatus(true);
      setLoading(false);
    }, 300);
  };

  if (loading) return <AppLoadingView />;

  if (
    props.isHelding &&
    getCurrentRaceDataMeta.loading &&
    !getCurrentRaceDataMeta?.data?.lobby
  ) {
    console.log("Показываю загркузу");
    console.log(getCurrentRaceDataMeta?.data?.lobby);

    return <AppLoadingView />;
  }

  const animationEndHandler = () => {
    if (finishedAnimations >= lobbySubscriptionMeta?.lobby?.users.length) {
      console.log("Все анимации гонки закончились");
      return;
    }
    setFinishedAnimations(finishedAnimations => finishedAnimations + 1);
  };

  if (finishedAnimations >= lobbySubscriptionMeta?.lobby?.users.length) {
  }

  //если зашли после запуска игры
  if (
    props.isHelding &&
    !getCurrentRaceDataMeta.loading &&
    getCurrentRaceDataMeta?.data?.lobby
  ) {
    console.log("зашли после запуска игры");

    console.log(getCurrentRaceDataMeta.data);
    return (
      <Race
        question={
          lobbySubscriptionMeta?.lobby.question
            ? lobbySubscriptionMeta?.lobby.question
            : getCurrentRaceDataMeta.data.lobby.question
        }
        users={
          lobbySubscriptionMeta?.lobby.users
            ? lobbySubscriptionMeta?.lobby.users
            : getCurrentRaceDataMeta.data.lobby.users
        }
        raceId={props.raceId}
        isHelding={true}
        animationEndHandler={animationEndHandler}
      />
    );
  }
  console.log("remder");

  if (timerSatus && !lobbySubscriptionMeta?.lobby?.users) {
    return <AppLoadingView />;
  }

  if (timerSatus && lobbySubscriptionMeta?.lobby?.users) {
    console.log("данные по подписке ниже");
    console.log(lobbySubscriptionMeta.lobby);

    return (
      <Race
        question={lobbySubscriptionMeta.lobby.question}
        users={lobbySubscriptionMeta.lobby.users}
        raceId={props.raceId}
        animationEndHandler={animationEndHandler}
      />
    );
  }

  // if (loading) return <AppLoadingView />;
  if (timerSatus === false) {
    return (
      <>
        <Timer
          callback={timerCallback}
          animationCallback={timerAnimationCallback}
          countNumber={15}
          delay={1}
          acceptedNumber={props.acceptedNumber}
          usersNumber={props.usersNumber}
          raceId={props.raceId}
        />
      </>
    );
  }
};

export default Game;
