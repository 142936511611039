import React, { FormEvent } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../../theme/types'
import {
  AdminMakeExportMutation,
  adminMakeExportMutation,
} from 'bridge'
import { Button, FormLayout, FormItem } from '@vkontakte/vkui'
import { useMutation } from '@apollo/react-hooks'
import dayjs from 'dayjs'
import AdminPage from '../AdminPage'
import config from '../../../config'
import {downloadLink } from '../../../utils/download-link'

const styles = makeStyles((theme: Theme) => ({
  valueId: {
    width: '300px',
  },
  valueRadio: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-5px',
  },
}));

interface IExportProps {
}

const Export =(props: IExportProps) => {
  const classes = styles();

  const [makeExportMutation] = useMutation<AdminMakeExportMutation,
    AdminMakeExportMutation.Arguments>(adminMakeExportMutation)


  const download = async (event: FormEvent) => {
    event.preventDefault()
    const {data} = await makeExportMutation()
    downloadLink(data.adminMakeExport.link)
  }

  return (
    <AdminPage title="Лобби">
      <FormLayout onSubmit={download}>
        <FormItem>
          <Button mode="commerce" size="l">Выгрузить CSV</Button>
        </FormItem>
      </FormLayout>
    </AdminPage>
  )
}

export default Export;
