import React, { memo } from "react"
import Game from "./Game";
import { useQuery } from "@apollo/react-hooks";
import AppLoadingView from '../views/AppLoadingView';

import {
  LobbyQuery,
  lobbyQuery,
  LobbyLeaderboardQuery,
  lobbyLeaderboardQuery
} from "bridge";
import LeadersBoard from '../LeadersBoard/LeadersBoard';

interface IGameLoaderProps {
  lobbyId: string;
  usersNumber: number;
}

/**
 * "Лицо" приложения. С этого компонента в приложении начинается весь визуал.
 * @type {React.NamedExoticComponent<object>}
 */

const GameLoader = memo(({lobbyId, usersNumber}: IGameLoaderProps) => {

  const { data, loading } = useQuery<LobbyQuery>(lobbyQuery, {
    variables: {
      lobbyId,
    },
  });

  const { data: leadersData, loading: leadersLoading } = useQuery<
    LobbyLeaderboardQuery,
    LobbyLeaderboardQuery.Arguments
  >(lobbyLeaderboardQuery, {
    variables: {
      lobbyId
    }
  });

  if (loading || leadersLoading) return <AppLoadingView />;

  if (
    leadersData?.lobbyLeaderboard[0]?.name &&
    leadersData.lobbyLeaderboard.length > 0
  ) {
    return <LeadersBoard data={leadersData.lobbyLeaderboard} />;
  }

  const isLaunched = !!data?.lobby?.startsIn

  console.log('GameLoaderData', data)
  return (
    <>
      <Game
        raceId={lobbyId}
        acceptedNumber={data.lobby.acceptedNumber}
        usersNumber={usersNumber}
        isHelding={isLaunched}
      />
    </>
  );
});

export default GameLoader;
