import config from '../config'
import dayjs from 'dayjs'

export const downloadLink = (link: string) => {
  const url = config.apiBaseUrl.replace(/\/+$/, '')
    + '/'
    + link.replace(/^\/+/, '')
  const aTag = document.createElement('a');
  aTag.setAttribute('href', url);
  aTag.style.display = 'none';
  document.body.appendChild(aTag);
  aTag.setAttribute('download', 'export_' + dayjs().format('YYYY-MM-DD HH:mm:ss') + '.csv');
  // aTag.setAttribute('target', '_blank')
  aTag.click();
}

export const downloadRaw = (link: string) => {
  const url = link
  const aTag = document.createElement('a');
  aTag.setAttribute('href', url);
  aTag.style.display = 'none';
  document.body.appendChild(aTag);
  aTag.setAttribute('download', 'export_' + dayjs().format('YYYY-MM-DD HH:mm:ss') + '.csv');
  // aTag.setAttribute('target', '_blank')
  aTag.click();
}

