import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as TimerSVG } from "../../assets/img/timer_start.svg";
import { ReactComponent as CarSVG } from "../../assets/img/car_start.svg";
import SVGRoad from "../../assets/img/road_start.base64.svg";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "../../theme/types";
import { Transition } from "react-transition-group";
import useInterval from "../../hooks/useInterval";
import gsap from "gsap";
import { useMutation, useSubscription } from "@apollo/react-hooks"
import { TransitionStatus } from "react-transition-group/Transition";
import { Button } from '@vkontakte/vkui'
import { lobbySubscription, LobbySubscription } from 'bridge'

const styles = makeStyles((theme: Theme) => ({
  /*   root: {
    height: "100%",
    width: "100%",
    position: "fixed",
    background: "white",
    top: 0,
    left: 0,
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: theme.typography.fontFamilyTT,
    transition: "left 500ms ease-in-out"
  }, */
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    fontFamily: theme.typography.fontFamilyTT,
    transition: "transform 500ms ease-in-out",
    transform: "translateX(0)"
  },
  container: {
    position: "relative",
    height: "85vh",
    width: "100%",
    flex: "0.85 0 0"
  },
  road: {
    width: "100%",
    height: "64px",
    bottom: 0,
    position: "absolute",
    zIndex: -1,
    background: "linear-gradient(180deg, #BACDF2 0%, #4B69A5 100%)",
    "&:after": {
      content: "' '",
      position: "absolute",
      bottom: 0,
      backgroundImage: `url(${SVGRoad})`,
      backgroundPosition: "center",
      backgroundRepeat: "repeat-x",
      backgroundSize: "cover",
      width: "100%",
      height: "10px"
    }
  },
  start: {
    position: "absolute",
    bottom: "62px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& svg": {
      width: "100%",
      height: "100%",
      maxHeight: "80vh"
    }
  },
  car: {
    position: "absolute",
    bottom: 8,
    width: "100%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      height: "100%",
      transform: "translateX(-350%)",
      flex: "0.7 0 0",
      maxWidth: "500px",
      "& .car__engine": {
        animation: `$animateEngine 200ms ease-in-out infinite`
      }
    }
  },
  "@keyframes animateEngine": {
    "0%": {
      transform: "translateY(0)"
    },
    "100%": {
      transform: "translateY(1px)"
    }
  },
  "@keyframes animateArm": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(0.2deg)"
    }
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "0.15 0 0",
    "& button": {
      width: "50%",
      height: "50px",
      background: "#4986CC",
      color: "white",
      border: "none",
      borderRadius: "15px",
      fontSize: "25px",
      outline: "none",
      "&:active": {
        background: "#497ebb"
      }
    }
  },
  time: {
    position: "absolute",
    left: "50%",
    width: "300px",
    textAlign: "center",
    top: "56%",
    transform: "translate(-50%, -50%)",
    fontSize: "101px",
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const Timer = (props: {
  countNumber: number;
  delay: number;
  raceId: string;
  callback: any;
  animationCallback: any;
  acceptedNumber: number;
  usersNumber: number;
}) => {
  const classes = styles();
  const carRef = useRef<SVGSVGElement>(null);

  const [timer, setTimer] = useState(props.countNumber);
  const [timerStatus, setTimerStatus] = useState(false);
  const [timerIn, setTimerIn] = useState(true);

  let acceptedNumber = props.acceptedNumber || 0
  const {
    data: lobbySubscriptionMeta,
    loading: changedQuestionLoading
  } = useSubscription<LobbySubscription, LobbySubscription.Arguments>(
    lobbySubscription,
    {
      variables: {
        lobbyId: props.raceId
      }
      //shouldResubscribe: true
    }
  );

  if (lobbySubscriptionMeta?.lobby) {
    acceptedNumber = lobbySubscriptionMeta.lobby.acceptedNumber
  }
  console.log('timer updated', acceptedNumber, lobbySubscriptionMeta)


  const animateCarExiting = () => {
    gsap.to(carRef.current, {
      translateX: "350%",
      duration: "0.5",
      ease: "power0"
    });
  };

  useEffect(() => {
    gsap.to(carRef.current, {
      translateX: "0%",
      duration: "0.5",
      ease: "power0"
    });
  }, []);

  useEffect(() => {
    if (timer === -1) {
      animateCarExiting();
      setTimeout(() => {
        setTimerIn(false);
      }, 500);
    } else if (timer === -2) {
      setTimerStatus(false);
    }
  }, [timer]);

  useInterval(
    () => {
      setTimer(timer - 1);
    },
    timerStatus ? props.delay * 1000 : null
  );

  const onButtonClick = () => {
    props.callback();
    setTimerStatus(true);
  };

  const transitionStyles: any = {
    exiting: { transform: "translateX(100%)" },
    exited: { transform: "translateX(100%)" }
  };

  const getPrettyTime = (time: number) => {
    if (time < 10) {
      return `00:0${time}`;
    }
    return `00:${time}`;
  };

  return (
    <Transition in={timerIn} timeout={500}>
      {(state: TransitionStatus) => {
        if (state === "exited") {
          props.animationCallback();
        }
        return (
          <>
            <div
              style={{ ...transitionStyles[state] }}
              className={classes.root}
            >
              <div className={classes.container}>
                <div className={classes.start}>
                  <TimerSVG />
                  <div className={classes.time}>
                    {timer <= -1 ? "GO" : getPrettyTime(timer)}
                  </div>
                </div>
                <div className={classes.car}>
                  <CarSVG ref={carRef} />
                </div>
                <div className={classes.road}></div>
              </div>
              <div className={classes.button}>
                {acceptedNumber > 0 ? (
                  <button onClick={() => onButtonClick()}>
                    Начать гонку (готовы {acceptedNumber} из {props.usersNumber})
                  </button>
                ) : (
                  <button disabled={true} style={{
                    opacity: 0.7,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    width: 'auto'
                  }}>
                    Для начала гонки необходим хотя бы 1 пользователь
                    (готовы {acceptedNumber} из {props.usersNumber})
                  </button>
                )}
              </div>
            </div>
          </>
        );
      }}
    </Transition>
  );
};

export default Timer;
