import React, { memo } from "react"

import NewTabButton from './NewTabButton'
import Admin from './Admin/Admin'

interface IAppProps {
  isVk: boolean;
}

/**
 * "Лицо" приложения. С этого компонента в приложении начинается весь визуал.
 * @type {React.NamedExoticComponent<object>}
 */

const App = memo((props: IAppProps) => {
  const {isVk} = props

  return (
    <>
      <Admin />
      {isVk && <NewTabButton />}
    </>
  );
});

export default App;
