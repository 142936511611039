import React, { FormEvent, MouseEvent, useState } from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../../theme/types'
import {
  AdminDay,
  adminCreateDayMutation,
  AdminCreateDayMutation,
  adminUpdateDayMutation,
  AdminUpdateDayMutation,
} from 'bridge'
import { Button, FormItem, FormLayout, Input, Spinner } from '@vkontakte/vkui'
import { useMutation } from '@apollo/react-hooks'

const styles = makeStyles((theme: Theme) => ({
  value: {},
  valueNumber: {
    width: '100px',
  },
  button: {
  },
}));

interface IDayItemProps {
  day: AdminDay;
  update: (form: AdminDay) => void;
}

const fixForm = (form: AdminDay): AdminDay => {
  const defaultForm = {...form}
  delete defaultForm.createdAt
  if ('__typename' in defaultForm) {
    // @ts-ignore
    delete defaultForm.__typename
  }
  return defaultForm
}

const DayItem =(props: IDayItemProps) => {
  const {day, update} = props;
  const [form, setForm] = useState(fixForm(day))
  const [hasChanges, setHasChanges] = useState(false)
  const [loading, setLoading] = useState(false)
  const classes = styles();

  // const { data, loading } = useQuery<AdminDaysQuery>(adminDaysQuery, {
  //   fetchPolicy: "network-only"
  // });

  const setFormProp = (name: string, value: any) => {
    setForm({...form, [name]: value})
    setHasChanges(true)
  }

  const onInput = ($event: FormEvent) => {
    const el = $event.target as HTMLInputElement
    const name = el.name
    const value = el.value
    if (el.type === 'number') {
      setFormProp(name, +value)
    } else if (el.name ==='hidden') {
      setFormProp(name, value === 'on')
    } else {
      setFormProp(name, value)
    }
  }

  const [updateMutation] = useMutation<AdminUpdateDayMutation,
    AdminUpdateDayMutation.Arguments>(adminUpdateDayMutation)

  const [createMutation] = useMutation<AdminCreateDayMutation,
    AdminUpdateDayMutation.Arguments>(adminCreateDayMutation)

  const save = async () => {
    let resultForm
    if (form.id) {
      const {data} = await updateMutation({
        variables: {
          day: form
        }
      })
      resultForm = data.adminUpdateDay
    } else {
      const {data} = await createMutation({
        variables: {
          day: form
        }
      })
      resultForm = data.adminCreateDay
    }
    update(resultForm)
    setForm(fixForm(resultForm))
    setHasChanges(false)
  }

  const prevent = (cb: (...cbArgs: any) => void, ...args: any) => {
    return (event: MouseEvent|FormEvent) => {
      event.preventDefault()
      cb(...args, event)
    }
  }

  return (
    <FormLayout onSubmit={prevent(save)}>
      <FormItem top="Дата (строго в формате DD-MM-YYYY!)">
        <div className={classes.value}>
          <Input name="date" value={form.date} onChange={onInput}/>
        </div>
      </FormItem>
      <FormItem top="Ограничение на количество игр">
        <div className={classes.valueNumber}>
          <Input name="maxGamesCount"
                 value={form.maxGamesCount} type="number" onChange={onInput}/>
        </div>
      </FormItem>
      <FormItem>
        <Button mode="commerce" disabled={!hasChanges} before={loading ? <Spinner/> : null}
                size="l">{form.id ? 'Сохранить' : 'Создать'}</Button>
      </FormItem>
    </FormLayout>
  )
}

export default DayItem;
