export interface Config {
  apiBaseUrl: string;
  wsUrl: string;
  fullscreenUrl: string;
}

let env = 'prod';
const match = window.location.href.match(/^https:\/\/[a-z-]+\.([a-z]+)/);

if (match) {
  env = match[1];
}

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const wsUrl = process.env.REACT_APP_WEBSOCKET_URL
const fullscreenUrl = process.env.REACT_APP_FULLSCREEN_URL

function getErr(envName: string) {
  return `Environment variable ${envName} not passed`;
}
if (!apiBaseUrl) {
  throw new Error(getErr('REACT_APP_API_BASE_URL'));
}
if (!wsUrl) {
  throw new Error(getErr('REACT_APP_WEBSOCKET_URL'));
}
if (!fullscreenUrl) {
  throw new Error(getErr('REACT_APP_FULLSCREEN_URL'));
}

// Здесь происходит загрузка конфига на основе переменных внешней среды. Стоит
// не забывать что только переменные, начинающиеся с REACT_APP будут загружены
// react-scripts
const config: Config = {
  apiBaseUrl,
  wsUrl,
  fullscreenUrl,
  // FIXME
  // apiBaseUrl: 'https://ada718983eef.eu.ngrok.io',
  // wsUrl: 'wss://ada718983eef.eu.ngrok.io/gql-ws',
  // apiBaseUrl: env === 'staging'
  //   ? 'https://back.staging.minirace.special.vk-apps.com'
  //   : `https://back.${env}.minirace.special.vk-apps.com/mini-race/api`,
  // wsUrl: `wss://back.${env}.minirace.special.vk-apps.com/gql-ws`,
};

export default config;
