import React, { memo } from "react";
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../theme/types'

const styles = makeStyles((theme: Theme) => ({
  list: {
    display: 'flex',
    height: '100%',
    padding: '30px',
    boxSizing: 'border-box',
    margin: '0',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  item: {
    listStyle: 'none',
    "&:not(:last-child)": {
      marginBottom: '10px'
    }
  },
  buttonLine: {
    width: '100%',
    position: 'absolute',
    left: '0',
    bottom: '0',
    transform: 'translateX(100%)',
    transition: 'transform 0.3s',
    borderBottom: '3px solid #3F8AE0'
  },
  button: {
    cursor: 'pointer',
    position: 'relative',
    fontSize: "20px",
    fontWeight: 'bold',
    outline: "none",
    background: 'none',
    border: 'none',
    overflow: 'hidden',
    color: '#3F8AE0',
    padding: '5px 5px',
    textAlign: 'left',
    "&:active": {
      // textDecoration: 'underline'
    },
  },
  buttonActive: {
    // textDecoration: 'underline',
    '& $buttonLine': {
      transform: 'translateX(0%)',
    }
  },
}));

interface IMenuProps {
  page: string;
  setPage: (name: string) => void;
  className: string|null;
}

const Menu = memo((props: IMenuProps) => {
  const classes = styles();
  const { page, setPage, className } = props

  const itemClass = (currentPage: string) => {
    return classes.button + (page === currentPage ? ' ' + classes.buttonActive : '');
  }

  return (
    <ul className={classes.list + ' ' + className}>
      <li className={classes.item}>
        <button className={itemClass('questions')} onClick={() => setPage('questions')}>
          Вопросы
          <div className={classes.buttonLine}/>
        </button>
      </li>
      <li className={classes.item}>
        <button className={itemClass('days')} onClick={() => setPage('days')}>
          Дни
          <div className={classes.buttonLine}/>
        </button>
      </li>
      <li className={classes.item}>
        <button className={itemClass('lobbies')} onClick={() => setPage('lobbies')}>
          Лобби
          <div className={classes.buttonLine}/>
        </button>
      </li>
      <li className={classes.item}>
        <button className={itemClass('users')} onClick={() => setPage('users')}>
          Пользователи
          <div className={classes.buttonLine}/>
        </button>
      </li>
      <li className={classes.item}>
        <button className={itemClass('export')} onClick={() => setPage('export')}>
          Выгрузка результатов
          <div className={classes.buttonLine}/>
        </button>
      </li>
      <li className={classes.item}>
        <button className={itemClass('settings')} onClick={() => setPage('settings')}>
          Настройки
          <div className={classes.buttonLine}/>
        </button>
      </li>
    </ul>
  )
})

export default Menu;
