import React, { useState } from "react"
import { AdminQuestion, FIELD, LOBBY_TYPE } from 'bridge'
import QuestionForm from './QuestionForm'
import dayjs from 'dayjs'
import Item from '../Item'
import ItemField from '../ItemField'
import { Checkbox } from '@vkontakte/vkui'

interface IQuestionItemProps {
  question: AdminQuestion;
  index: number;
  checked: boolean;
  onCheckChange: (value: boolean) => void;
}

const QuestionItem =(props: IQuestionItemProps) => {
  const {question, index, checked, onCheckChange} = props;
  const [form, setForm] = useState({...question})
  if (form.hidden !== question.hidden) {
    setForm({...question})
  }

  const getTypeTitle = (type: string) => {
    const typeTitles: {[key: string]: string} = {
      [LOBBY_TYPE.COMMON]: 'Общий',
      [LOBBY_TYPE.SEMI_FINAL]: 'Полуфинал',
      [LOBBY_TYPE.FINAL]: 'Финал',
    }
    return typeTitles[type] || 'Не найден'
  }
  const getFieldTitle = (field: string) => {
    const typeTitles: {[key: string]: string} = {
      [FIELD.ANDROID]: 'Android',
      [FIELD.IOS]: 'IOS',
      [FIELD.QA]: 'QA',
      [FIELD.BACKEND]: 'Backend',
      [FIELD.FRONTEND]: 'Frontend',
    }
    return typeTitles[field] || 'Не найден';
  }

  const isNew = form.id === '';

  const date = dayjs(form.createdAt).format('YYYY-MM-DD HH:mm:ss');

  return (
    <Item
      editForm={<QuestionForm question={form} getTypeTitle={getTypeTitle}
                              getFieldTitle={getFieldTitle} update={setForm}/>}
      isNew={isNew}
      isHidden={form.hidden}
      index={index}
    >
      <ItemField>
        <Checkbox checked={checked} onChange={(e) => onCheckChange(e.target.checked)}/>
      </ItemField>
      <ItemField label="Вопрос" fontWeight={400} grow={1}>
        {form.title}
      </ItemField>
      <ItemField label="Дистанция">
        {form.distance}
      </ItemField>
      <ItemField label="Тип" style={{ width: '80px' }}>
        {getTypeTitle(form.type)}
      </ItemField>
      <ItemField label="Направление">
        {getFieldTitle(form.field)}
      </ItemField>
      <ItemField label="Дата создания">
        {date}
      </ItemField>
    </Item>
  )
}

export default QuestionItem;
