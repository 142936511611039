import React, { memo } from "react";
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../theme/types'
import { Icon28ArrowLeftOutline } from '@vkontakte/icons';

const styles = makeStyles((theme: Theme) => ({
  button: {
    cursor: 'pounter',
    position: 'fixed',
    left: '5px',
    top: '5px',
    width: '40px',
    height: '40px',
    background: "#4986CC",
    color: "white",
    border: "none",
    padding: '6px',
    borderRadius: "3px",
    fontSize: "25px",
    outline: "none",
    display: 'block',
    "&:active": {
      background: "#497ebb"
    },
  },
}));

interface IBackButtonProps {
  goBack: () => void;
}

const BackButton = memo(({goBack}: IBackButtonProps) => {
  const classes = styles();

  return (
    <button className={classes.button} onClick={goBack}>
      <Icon28ArrowLeftOutline/>
    </button>
  )
})

export default BackButton;
