import React, { memo } from "react";
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../theme/types'
import { Icon16Fullscreen } from '@vkontakte/icons';
import config from '../config'

const styles = makeStyles((theme: Theme) => ({
  button: {
    position: 'fixed',
    right: '5px',
    top: '5px',
    width: '16px',
    height: '16px',
    background: "#4986CC",
    color: "white",
    border: "none",
    padding: '7px',
    borderRadius: "3px",
    fontSize: "25px",
    outline: "none",
    "&:active": {
      background: "#497ebb"
    }
  },
}));

interface INewTabButtonProps {
}

const NewTabButton = memo((props: INewTabButtonProps) => {
  const classes = styles();

  const link = `${config.fullscreenUrl}${window.location.search}&vk=0`

  return (
    <a className={classes.button} href={link} target="_blank" rel="noreferrer">
      <Icon16Fullscreen/>
    </a>
  )
})

export default NewTabButton;
