import React from "react";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import { styled } from "@material-ui/styles";
import { EColor } from "../Car/types";
import chooseColor from "../../utils/chooseColor";
import { Theme } from "../../theme/types";

const styles = makeStyles((theme: Theme) => ({
  root: {
    margin: "66px 171px 58.5px",
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium
  },
  container: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    width: "100%",
    minHeight: "80px",
    display: "flex",
    justifyContent: "center"
  },
  playerInfo: {
    display: "flex",
    alignItems: "center",
    marginLeft: "30px",
    "&:first-child": {
      marginLeft: "0"
    }
  },
  playerName: {
    fontWeight: 'bold',
    fontSize: '35px',
    lineHeight: '40px',
    letterSpacing: '-0.408px',
    display: "flex",
    flexDirection: "column"
  }
}));

interface IPlayerNumberProps {
  color: string;
}
const PlayerInfoNumber = styled("span")<{}, IPlayerNumberProps>({
  fontWeight: 'bold',
  fontSize: '35px',
  lineHeight: '80px',
  letterSpacing: '-0.408px',
  position: "relative",
  paddingRight: "45px",
  marginRight: "15px",
  paddingTop: "7px",
  "&:after": {
    content: "' '",
    display: "block",
    position: "absolute",
    top: "50%",
    right: "-3px",
    transform: "translateY(-50%)",
    backgroundColor: props => props.color,
    width: "35px",
    height: "5px"
  }
});

interface IPlayerInfoProps {
  data: {
    name: string;
    costumeColor: EColor;
  }[];
}
const PlayersInfo = (props: IPlayerInfoProps) => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <ul className={classes.container}>
        {props.data.map(({ name, costumeColor }, i) => {
          const splitName = name.split(" ");
          console.log(splitName);
          const color = chooseColor(costumeColor)[0]

          return (
            <li
              key={i}
              className={classes.playerInfo}
              style={{ color }}
            >
              <PlayerInfoNumber key={i} color={color}>
                {i + 1}
              </PlayerInfoNumber>
              <div className={classes.playerName}>
                <span>{splitName[1]}</span>
                <span>{splitName[0]}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PlayersInfo;
